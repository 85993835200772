import React, { Component } from 'react'

export class Beauty extends Component {
  render() {
    return (
      <div>
        <h2>What is beauty to you? How Can you produce it?</h2>
        <p>Beauty is not only how something looks. It can be how something moves. How it learns. How it never gives up.</p>
      </div>
    )
  }
}

export default Beauty
