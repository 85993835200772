import React, { Component } from 'react'
import tri from '../assets/tri.jpg';

export class Hero extends Component {
  render() {
    return (
      <img src={tri}  alt="tri" width="100%"/>
    )
  }
}

export default Hero
