import React, { Component } from 'react'
import './App.css';
// import Gradient from '../assets'

export class SelfCompassion extends Component {
  render() {
    return (
      <div className="section__image">
        <h2>Self Compassion</h2>
        <p>Self-compassion is an attitude of generosity, honesty, and kindness towards oneself.</p>
        <p>It comes from a deep desire to ease suffering, and to recognize that mistakes and discomfort are part of the human condition.</p>
        <p>Take this self compassion <a href="https://self-compassion.org/test-how-self-compassionate-you-are/">assessment</a></p>
        <ul>
          <h3>There are three parts to Self-compassion</h3>
          <li>Self-kindness</li>
          <li>Common humanity</li>
          <li>Mindfullness</li>
        </ul>
        <p>Why is self compassion important?</p>
        <p>self-compassionate people take more responsibility for fixing mistakes than self-criticizers.</p>
        <p>People who practice self-compassion while dieting lose more weight and keep it off for longer.</p>
        {/* <p>People who practice self-compassion while dieting lose more weight and keep it off for longer.</p> */}
      </div>
    )
  }
}

export default SelfCompassion
