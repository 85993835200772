import React, { Component } from 'react'
import Nav from '../components/Nav'
import Hero from '../components/Hero'

export class Header extends Component {
  render() {
    return (   
      <header className="App-header">
        <Nav/>
        <Hero/>
      </header>
    )
  }
}

export default Header
