import './App.css';
import Header from '../components/Header'
import About from '../components/About'
import Beauty from '../components/Beauty'
import SelfCompassion from '../components/SelfCompassion'
import Mindset from '../components/Mindset'

function App() {
  return (
    <div className="App">
      <Header/>
      <About/>
      <Beauty/>
      <SelfCompassion/>
      <Mindset/>
    </div>
  );
}

export default App;
