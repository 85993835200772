import React, { Component } from 'react'
import './App.css';
// import Gradient from '../assets'

export class Mindset extends Component {
  render() {
    return (
      <div className="section__image">
        <h2>Mindset</h2>
        <p>Do we set our minds or are our minds a set of programs (thoughts, emotions, behaviors) that can be triggered by our environment, situation, smell, sound, sight.</p>
        <p>If you you become aware of your triggers you can use them to your advantge</p>
        <p>In fact we have triggers for both good and bad behavior, thought, and emotion.</p>
        <p>Let us help you identify and work with your triggers.</p>
        <p>Neurology of self-talk</p>
        <p>What are the thoughts?</p>
        <p>Do you believe them?</p>
        <p>Do these thoughts create a feeling in your body?</p>
        <a href="https://my.precisionnutrition.com/certification#/414840/card/129582606" target="_blank">Understand will power and how it can work for you.</a>
        <p>the ability to delay gratification, resisting short-term temptations in order to meet long-term goals</p>
      </div>
    )
  }
}

export default Mindset
