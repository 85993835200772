import React, { Component } from 'react'

export class Nav extends Component {
  render() {
    return (
      <div>
        <h1>Hermota Fitness</h1> 
        <div className="nav">
          <li>Beauty</li>
          <li>Self-compassion</li>
          <li>Mind</li>
          <li>Body</li>
          <li>Community</li>
          <li>Sport</li>
          <li>Results</li>
          <li>Login</li>
          <li>Tools</li>
          <li>digital and Tech</li>
          <li>Fun</li>
        </div>
      </div>
    )
  }
}

export default Nav
