import React, { Component } from 'react'

export class About extends Component {
  render() {
    return (
      <div className="about__section">About</div>
    )
  }
}

export default About
